<template>
    <div id="avatar" class="hide-scrollbar" :class="$mq">
        <template v-if="['landscape', 'desktop'].includes($mq)">
            <div class="main-avatar">
                <div class="avatar-title">{{ $t('user.avatar_title') }}</div>
                <div class="img" v-if="avatar">
                    <img v-if="avatar" class="main-img" v-bind:src="avatar" />
                </div>
            </div>
        </template>
        <div class="available-avatar">
            <div class="subtitle">{{ $t('user.avatar_subtitle') }}</div>
            <div class="img-container scrollbar">
                <img class="avatar-img" v-for="image in images" :key="image.pathLong" v-bind:src="image.pathLong" :class="{ active: image.pathShort.slice(2) == userAvatar }" @click="setAvatar(image)" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        userAvatar: undefined,
        user_id: undefined
    },
    name: 'avatarSelector',
    data() {
        return {
            images: []
        }
    },
    computed: {
        avatar() {
            if (!this.userAvatar.includes('//')) {
                return css_image_url + 'avatar/' + this.userAvatar
            } else {
                return this.userAvatar
            }
        }
    },
    created() {},
    mounted() {
        log(this.avatar)
        this.importAll(require.context('../../../../public/img/avatar/', true, /\.svg$/))
    },
    methods: {
        importAll(r) {
            r.keys().forEach((key) => this.images.push({ pathLong: r(key), pathShort: key }))
        },
        setAvatar(image) {
            var img = image.pathShort.slice(2)
            var params = {
                avatar: img,
                id: this.user_id
            }
            this.$store.dispatch('employee/changeAvatar', params)
        }
    },
    watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#avatar {
    @include background($color: $default-bg-color);
    @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba(159, 162, 166, 0.25));
    width: 100%;
    height: 100%;

    .main-avatar {
        height: 250px;

        .avatar-title {
            @include font-size(m);
            font-family: $text-sbold;
            color: $main;
            text-align: center;
            padding: 20px 0px 10px 0px;
        }
        .img {
            text-align: center;

            .main-img {
                width: 150px;
                height: 150px;
                margin-bottom: 40px;
            }
        }
    }

    .available-avatar {
        height: calc(100% - 250px);

        .subtitle {
            @include font-size(m);
            font-family: $text-sbold;
            color: $main;
            text-align: center;
            height: 30px;
        }
        .img-container {
            @include display-flex();
            @include flex-wrap(wrap);
            @include justify-content();
            @include align-content(flex-start);
            width: 100%;
            height: calc(100% - 30px);
            overflow: auto;
            padding: 10px;

            .avatar-img {
                @include interaction();
                margin: 10px;
                width: 60px;
                height: 60px;
                position: relative;
                top: 0;

                &:hover {
                    top: -12px;
                }
                &.active {
                    opacity: 0.3;
                }
            }
        }
    }

    @media (max-width: 1000px) {
        .main-avatar {
            height: 175px;

            .img .main-img {
                width: 100px;
                height: 100px;
                margin-bottom: 20px;
            }
        }

        .available-avatar {
            height: calc(100% - 175px);

            .img-container .avatar-img {
                width: 50px;
                height: 50px;
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#avatar.hide-scrollbar.portrait {
    background: none;
    box-shadow: none;
    width: 100%;
    height: 100%;

    .available-avatar {
        height: 100%;
        padding-bottom: 10px;

        .subtitle {
            @include font-size(m);
            font-family: $text-bold;
            color: $main-dark;
        }
    }
}
</style>
